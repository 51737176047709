var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12 col-12 mb-4 mt-4 pb-2"
  }, [_c('div', [_c('div', {
    staticClass: "row d-flex justify-content-center"
  }, [_c('h5', {
    staticStyle: {
      "text-transform": "uppercase"
    }
  }, [_vm._v(_vm._s(_vm.$t("amendment.receiver-info")))])]), _c('div', {
    staticClass: "row d-flex justify-content-end"
  }, [_c('div', {
    staticClass: "col-lg-12 d-flex justify-content-end"
  }, [[0, 1, 4].includes(_vm.amendment_status) ? _c('b-btn', {
    staticClass: "btn-sm btn-primary",
    on: {
      "click": _vm.btnmodalPenerima
    }
  }, [_vm._v(_vm._s(_vm.$t("amendment.add-receiver")))]) : _vm._e()], 1)]), _c('div', {
    staticClass: "row mt-2"
  }, [_c('div', {
    staticClass: "col-lg-12"
  }, [_c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table table-hover table-sm"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(_vm._s(_vm.$t("index")))]), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(_vm._s(_vm.$t("name")))]), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(_vm._s(_vm.$t("mykad")) + " / " + _vm._s(_vm.$t("passport")))]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("relationship")) + " ")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(_vm._s(_vm.$t("part")))]), [0, 1, 4].includes(_vm.amendment_status) ? _c('th', {
    staticClass: "text-center",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("action")) + " ")]) : _vm._e()])]), this.receiverProperty.length == 0 ? _c('tbody', [_c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "6"
    }
  }, [_c('h5', {
    staticClass: "mt-5"
  }, [_vm._v(_vm._s(_vm.$t("no-info")))])])])]) : _vm._e(), this.receiverProperty.length != 0 ? _c('tbody', _vm._l(_vm.receiverProperty, function (item, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_vm._v(_vm._s(item.receiver.name))]), !item.receiver.passport_number ? _c('td', [_vm._v(_vm._s(item.receiver.ic_number))]) : _c('td', [_vm._v(_vm._s(item.receiver.passport_number))]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(" " + _vm._s(item.receiver.relationship) + " ")]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(item.portion))]), _c('td', {
      staticClass: "text-center"
    }, [_c('div', {
      staticClass: "btn-group-vertical",
      attrs: {
        "role": "group",
        "aria-label": "Vertical button group"
      }
    }, [[0, 1, 4].includes(_vm.amendment_status) ? _c('b-button', {
      staticClass: "btn btn-secondary btn-sm",
      attrs: {
        "tag": "button",
        "block": ""
      },
      on: {
        "click": function ($event) {
          return _vm.updateReceiverModal(item, index);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("update")) + " ")]) : _vm._e(), [0, 1, 4].includes(_vm.amendment_status) ? _c('b-button', {
      staticClass: "btn btn-danger btn-sm",
      attrs: {
        "tag": "button",
        "block": ""
      },
      on: {
        "click": function ($event) {
          return _vm.deleteReceiver(item.id, item.receiver.id, index);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("remove")) + " ")]) : _vm._e()], 1)])]);
  }), 0) : _vm._e()])])])]), _c('div', {
    staticClass: "row mt-4"
  }, [_c('div', {
    staticClass: "col-lg-12 text-right"
  }, [_c('div', {
    staticClass: "form-group"
  }, [[0, 1, 4].includes(_vm.amendment_status) ? _c('b-btn', {
    staticClass: "btn btn-primary",
    attrs: {
      "to": {
        name: 'pindaan.form-pindaan-dokumen-tidak-alih',
        params: {
          wasiat_id: this.wasiat_id
        },
        query: {
          property_id: this.property_id,
          pengesahan: _vm.pengesahan
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("save-next")))]) : _vm._e()], 1)])])])]), _c('b-modal', {
    ref: "modalPenerima",
    attrs: {
      "title": "Tambah penerima",
      "ok-title": _vm.$t('save'),
      "cancel-title": _vm.$t('cancel')
    },
    on: {
      "ok": _vm.hideModal,
      "cancel": _vm.hideModalReceiver
    }
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "form-group col-12",
    class: {
      'has-error': _vm.$v.register_receiver_data.receiver_id.$error
    }
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("fullname")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.register_receiver_data.receiver_id.$model,
      expression: "$v.register_receiver_data.receiver_id.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": ""
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.register_receiver_data.receiver_id, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, function ($event) {
        return _vm.onChangePenerima($event);
      }]
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }), _vm._l(_vm.receiver_array, function (receiver) {
    return _c('option', {
      key: receiver.id,
      domProps: {
        "value": receiver.id
      }
    }, [_vm._v(" " + _vm._s(receiver.name) + " ")]);
  })], 2), _vm.$v.register_receiver_data.receiver_id.$error && !_vm.$v.register_receiver_data.receiver_id.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("fullname")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])]), this.register_receiver_data.receiver_id > 0 ? _c('div', [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "form-group col-12"
  }, [_c('label', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.$t("mykad")) + " / " + _vm._s(_vm.$t("passport")) + ":")]), !this.penerima_passport ? _c('p', {
    staticClass: "form-control-plaintext"
  }, [_vm._v(_vm._s(this.penerima_ic))]) : _c('p', {
    staticClass: "form-control-plaintext"
  }, [_vm._v(_vm._s(this.penerima_passport))])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "form-group col-12"
  }, [_c('label', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.$t("relationship")) + ": ")]), _c('p', {
    staticClass: "form-control-plaintext"
  }, [_vm._v(_vm._s(this.penerima_relationship))])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "form-group col-12",
    class: {
      'has-error': _vm.$v.register_receiver_data.portion.$error
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Bahagian "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "input-group"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.register_receiver_data.portion.$model,
      expression: "$v.register_receiver_data.portion.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "Masukkan nombor dalam peratus (50)"
    },
    domProps: {
      "value": _vm.$v.register_receiver_data.portion.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.register_receiver_data.portion, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  })]), _vm.$v.register_receiver_data.portion.$error && !_vm.$v.register_receiver_data.portion.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(_vm._s(_vm.$t("part")) + " " + _vm._s(_vm.$t("validation.cannot-blank")))]) : _vm._e(), _vm.$v.register_receiver_data.portion.$model && (!_vm.$v.register_receiver_data.portion.$model.includes('%') && !_vm.$v.register_receiver_data.portion.$model.includes('/') || !Array.from(_vm.$v.register_receiver_data.portion.$model.split('')).some(function (char) {
    return !isNaN(char);
  })) ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("part")) + " " + _vm._s(_vm.$t("validation.portion-rules")) + " ")]) : _vm._e()])])]) : _vm._e()])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }